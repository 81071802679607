



















































































































import Vue from "vue";
import * as qr from "qrcode-generator";

export default Vue.extend({
  name: "OrderQRCode",

  props: {
    orderId: {
      type: String,
      default: null,
    },
    from: {
      type: String,
      default: "view-order",
    },
    pagination: {
      type: String,
      default: null,
    },
  },

  data: function () {
    let imgData: Array<string> = [];
    let imgTagArray: Array<string> = [];
    let activeShop = this.$store.state.shops.activeShop;
    let loading = this.$store.state.shops.loading;
    let orders = this.$store.state.orders;
    let orderAddress = this.$store.state.address;
    return {
      orders,
      orderAddress,
      selectedOrder: {},
      printing: false,
      imgData,
      imgTagArray,
      activeShop,
      loading,
    };
  },

  created: function () {
    this.$store.dispatch("orders/fetchOrderPickupAddress");
    this.$store.dispatch("orders/fetchOrder", this.$props.orderId);
  },

  mounted: async function () {
    // done printing or cancelled
    window.onafterprint = (e: any) => {
      this.$data.printing = false;
      if (this.$props.from === "view-order") {
        this.$router.push({ name: "view-order" });
      } else {
        this.$router.push({
          name: "active-orders",
          params: { from: "5", pagination: this.$props.pagination },
        });
      }
    };

    this.$watch(
      "orders",
      (newVal: any, oldVal: any) => {
        if (newVal.selectedOrder != null && oldVal.selectedOrder != null) {
          this.selectedOrder = newVal.selectedOrder;
          this.orderAddress = newVal.orderAddress;
          this.draw(newVal.selectedOrder.parcels.count);
        }
      },
      { immediate: true, deep: true }
    );
  },

  methods: {
    back() {
      if (this.$props.from === "view-order") {
        this.$router.push({ name: "view-order" });
      } else {
        this.$router.push({
          name: "active-orders",
          params: { from: "5", pagination: this.$props.pagination },
        });
      }
    },
    async draw(parcels: any) {
      let numberOfPackages = parcels;
      this.clearDuplicates();
      for (let i = 1; i <= numberOfPackages; i++) {
        let qrCode = qr.default(0, "L");

        const orderId = `${this.$props.orderId}`;
        let packageId = "";

        packageId = (orderId + "." + i).toString();

        this.$data.imgData.push(packageId);

        qrCode.addData(packageId);
        qrCode.make();

        this.$data.imgTagArray.push(qrCode.createImgTag(4, 5));
      }
    },
    clearDuplicates() {
      this.$data.imgData = [];
      this.$data.imgTagArray = [];
    },
    async print() {
      await this.$nextTick();
      this.$data.printing = true;

      let section = document.getElementById("print");

      // clear element if order was previously printed
      if (section) {
        const element = document.getElementById("print");
        if (element != null) {
          element.outerHTML = "";
        }
      }

      window.print();
    },
  },
});
